<template>
	<form-update-password />
</template>
<script>
import FormUpdatePassword from '@/components/user/password/UpdatePassword.vue';

export default {
	name: 'UserPermissionsPage',
	components: { FormUpdatePassword },
};
</script>
